<template>
   <div class="login">
      <div class="container">
        <div class="row justify-content-center align-items-center d-flex vh-100">
          <div class="col-lg-5 mx-auto">
            <div class="osahan-login p-5 bg-dark">
              <div class="text-center mb-4">
                <router-link to="/"><img src="../../assets/image/yallagame.png" style="height: 50px;" alt=""></router-link>
                 <h5 class="font-weight-bold mt-3" style="color: #fff;"> إلغاء الإشتراك </h5>
              </div>
              <form  @submit.prevent="unsubscribe()">
                <!-- <button class="btn btn-warning btn-lg btn-block text-uppercase" type="submit">تسجيل الدخول</button> -->
                <button class="btn btn-warning btn-lg btn-block text-uppercase" type="submit">
                <span v-if="loading" class="px-1 arabickufi">جاري الغاء الاشتراك</span> &nbsp;
                <div v-if="loading" class="spinner-border spinner-border-sm"></div>
                <span v-else class="arabickufi">الغاء الاشتراك</span>
            </button>

              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { HTTPSPA } from '@/Api/http-Spay';
import { useToast } from "vue-toastification";
import { useRouter, useRoute } from "vue-router";
import { useCookie } from 'vue-cookie-next'
import { ref } from 'vue';
export default {
   name: 'AppUnsubscribes',
   setup() {
        const router = useRouter();
        const route = useRoute();
        const cookie = useCookie();
        const toast = useToast();
        const loading = ref(false);

        const unsubscribe = async () => {
          loading.value = true;
          if (cookie.isCookieAvailable('STC')) {
              await HTTPSPA.post(`StcunSubscribeToService.php?msisdn=${cookie.getCookie("msisdn")}&serviceId=3754`).then((response) => {
                  if (response.data.message == '1010') {
                      toast.success("تم الغاء اشتراكك بنجاح في خدمة يلا قيم");
                      // cookie.keys().forEach(cookie => cookie.removeCookie(cookie))
                      cookie.removeCookie('msisdn')
                      cookie.removeCookie('status')
                      cookie.removeCookie('STC')
                      router.push('/')
                  } else if (response.data.message == '-70') {
                      toast.error("ليست لديك اشتراك في خدمة يلا قيم",);
                      cookie.removeCookie('msisdn')
                      cookie.removeCookie('status')
                      cookie.removeCookie('STC')
                      router.push('/')
                  } else {
                      toast.error("ليست لديك اشتراك في خدمة يلا قيم",);
                      cookie.removeCookie('msisdn')
                      cookie.removeCookie('status')
                      cookie.removeCookie('STC')
                      router.push('/')
                  }
              }).catch((error) => {
                  console.log(error);
              }).finally(() => {
                  loading.value = false;
              });
          } else {
              router.push('/')
          }
          
        }

        return { unsubscribe, loading}
   }
}
</script>

<style scoped>
.btn-warning {
  color: #fff;
  border-color: rgb(131, 203, 206);
  background: #315b10 none repeat scroll 0% 0% !important
}

.bg-dark {
  background-image: linear-gradient(0deg,#315b10 0,#67bb27 );
}
</style>